import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import ContentWrapper from "../components/ContentWrapper";
import FixtureCard from "../components/FixtureCard";
import SEO from "../components/SEO";

const CardsHeader = styled.div`
  display: grid;
  grid-template-columns: 4fr 5fr 9fr;
  padding: 1rem 1rem;
  color: #999999;
`;

const FixturesPage = ({ data }) => {
  const fixtures = data.allFixturesJson.nodes;

  return (
    <Layout title="Fixtures">
      <SEO pagetitle="Fixtures" pagedesc="対局予定" />
      <ContentWrapper>
        <CardsHeader>
          <div>Date</div>
          <div>Match</div>
          <div>Player</div>
        </CardsHeader>
        {fixtures.map((fixture) => (
          <FixtureCard data={fixture} />
        ))}
      </ContentWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allFixturesJson {
      nodes {
        date
        first
        game
        note {
          name
          link
        }
        second
        place
      }
    }
  }
`;

export default FixturesPage;
